@import "config";

body {
background-color: #fafafa;

@include breakpoint(mobile) {
@include font-size(13,24);
}


//ヘッダー
header {
max-width: 1230px;
padding: 20px 15px 0 15px;
margin: 0 auto 100px auto;
display: -webkit-flex;
display: flex;
-webkit-justify-content: space-between;
justify-content: space-between;
-webkit-align-items: center;
align-items: center;

@include breakpoint(mobile) {
margin: 0 auto 50px auto;
display: block;
}


h1 {
font-family: 'Dancing Script', cursive;
@include font-size(50,50);
text-transform: capitalize;

@include breakpoint(mobile) {
margin: 0 0 20px 0;
}
}

p {
display: none;

@include breakpoint(mobile) {
display: block;
font-family: 'Libre Baskerville', serif;
@include font-size(20,20);
letter-spacing: 0.03em;
text-transform: capitalize;
margin: 0 0 20px 0;
}
}

nav {
@include breakpoint(mobile) {
border-top: 1px solid #4b4b4b;
border-bottom: 1px solid #4b4b4b;
}

ul {
display: -webkit-flex;
display: flex;

@include breakpoint(mobile) {
padding: 10px 5px;
}

li {
font-family: 'Lato', sans-serif;
@include font-size(17,17);
letter-spacing: 0;
text-transform: capitalize;
margin: 0 50px 0 0;

@include breakpoint(mobile) {
@include font-size(13,13);
margin: 0 15px 0 0;
}

small {
@include font-size(13,13);
margin: 0 0.2em;
}

a {
transition: 0.3s;
}

a:hover {
@include link_color();
}
}

li:last-child {
padding: 0 20px 0 0;
margin: 0 0 0 0;
background-image: url(../../images/icon_link.svg);
background-size: 15px 11px;
background-position: right center;
background-repeat: no-repeat;
}
}
}
}


main {
max-width: 1230px;
padding: 0 15px;
margin: 0 auto 100px auto;
display: -webkit-flex;
display: flex;
-webkit-justify-content: space-between;
justify-content: space-between;

@include breakpoint(mobile) {
margin: 0 auto 50px auto;
display: block;
}


//日付、見出し、要約文の共通
article {
ul {
display: -webkit-flex;
display: flex;
@include sub-color();
padding: 0 0 20px 0;

@include breakpoint(mobile) {
padding: 0 0 10px 0;
}

li:first-child {
font-family: 'Lato', sans-serif;
@include font-size(12,12);
}

li:first-child::after {
content: "　/　";
}

li:last-child {
@include font-size(13,13);
}
}

h2 {
font-family: 'GeneiKoburiMincho';
@include font-size(22);
letter-spacing: -0.08em;
padding: 0 0 10px 0;

@include breakpoint(mobile) {
@include font-size(20);
}
}

p {
@include font-size(14,24);
}

#pagenation {
width: 100%;
margin: 100px 0 0 0;
display: flex;
-webkit-justify-content: center;
justify-content: center;

@include breakpoint(mobile) {
margin: 50px 0 0 0;
}

span {
background-color: #c8c8c8;
}
}

.dots {
border: none !important;
background-color: #FFF !important;
}

.page-numbers {
line-height: 1.8;
padding: 0 7px;
margin: 0 2px;
display: block;
border: 1px solid #c8c8c8;
transition: ease 0.3s;
}

.page-numbers:hover {
background-color: #c8c8c8;
}
}


//ニュース
#news {
width: 60%;

@include breakpoint(mobile) {
width: 100%;
margin: 0 0 50px 0;
}

section {
padding: 40px 10px;
border-bottom: 1px dotted #969696;

@include breakpoint(mobile) {
padding: 20px 5px;
}

a {
transition: 0.3s;
}

a:hover {
opacity: 0.5;
}
}

section:first-child {
border-top: 1px dotted #969696;
}

img {
margin: 0 0 20px 0;
}

#postlink {
margin: 100px 0 0 0;
display: -webkit-flex;
display: flex;
-webkit-justify-content: space-between;
justify-content: space-between;

@include breakpoint(mobile) {
margin: 50px 0 0 0;
}

li {
a {
transition: 0.3s;
}

a:hover {
@include link-color();
}
}

li:first-child,
li:last-child {
font-family: 'NotoRegular';
@include font-size(14,14);
@include text-color();
}

li:first-child::after {
content: "";
}
}
}


//コラム
#column {
margin: 0 auto;

section {
margin: 0 0 50px 0;

@include breakpoint(mobile) {
display: block;
}

a {
display: -webkit-flex;
display: flex;
-webkit-justify-content: space-between;
justify-content: space-between;
align-items: -webkit-center;
align-items: center;
transition: 0.3s;

@include breakpoint(mobile) {
display: block;
}

div:first-child {
width: 30%;

@include breakpoint(mobile) {
width: 100%;
margin: 0 0 20px 0;
}

img {
width: 100%;
max-height: 240px;
object-fit: cover;
}
}

div:last-child {
width: 68%;

@include breakpoint(mobile) {
width: 100%;
}
}
}

a:hover {
opacity: 0.5;
}
}

section:last-child {
margin: 0 0 0 0;
}

#detail {
max-width: 800px;

p {
margin: 0 0 20px 0;
}

img {
width: 100%;
height: auto;
margin: 0 0 20px 0;
}
}
}


//サイドバー
#sidebar {
width: 30%;

@include breakpoint(mobile) {
width: 100%;
}

ul {
.categories {
@include font-size(18,18);
@include sub-color();

@include breakpoint(mobile) {
margin: 0 0 10px 0;
}

ul {
margin: 20px 0 0 0;

@include breakpoint(mobile) {
margin: 10px 0 0 0;
}

li {
@include font-size(14,14);
padding: 20px 10px;
border-bottom: 1px solid #c8c8c8;

@include breakpoint(mobile) {
padding: 15px 5px;
}

a {
transition: 0.3s;
}

a:hover {
@include link-color();
}
}

li:first-child {
border-top: 1px solid #c8c8c8;
border-bottom: 1px solid #c8c8c8;
}
}
}
}
}//sidebar
}
}